import httpClient from '@/libs/http-client'

// 카테고리 목록
export function getCategoryList (data) {
  const url = '/api/admin/board/category'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 카테고리 설정
export function setCategory (data) {
  const url = '/api/admin/board/categorySave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 댓글 설정
export function cmtSave (data) {
  const url = '/api/admin/board/cmtSsave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 고객센터관리
export function boardList (boardType, data) {
  const url = `/api/admin/board/${boardType}/list`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function boardSave (boardType, data) {
  const url = `/api/admin/board/${boardType}/save`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function boardDetail (boardType, data) {
  const url = `/api/admin/board/${boardType}/datail`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 쪽지 리스트
export function msgList (boardType, data) {
  const url = `/api/admin/board/${boardType}`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgSend (data) {
  const url = '/api/admin/board/msgSend'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgUpdate (data) {
  const url = '/api/admin/board/msgUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 상단슬라이드발송
export function msgHeadSave (data) {
  const url = '/api/admin/board/msgHeadSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgJoinSave (data) {
  const url = '/api/admin/board/msgJoinSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function currentSave (data) {
  const url = '/api/admin/board/answer/save'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function replyList (data) {
  const url = '/api/admin/board/answer/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function replyDelete (data) {
  const url = '/api/admin/board/answer/delete'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function saveFlowBoard (data) {
  const url = '/api/admin/board/snt/save'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function noticeOrderChange (data) {
  const url = '/api/admin/board/noticeTopChange'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function noticeList (data) {
  const url = '/api/admin/board/nt/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function noticeViewYn (data) {
  const url = '/api/admin/board/viewYn'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
